// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
ActiveStorage.start()

import "./bootstrap-multiselect.min.js"

window.addEventListener('DOMContentLoaded', (event) => {
  $('.commitment-select').multiselect({
    buttonTextAlignment: 'left',
    onChange: function() {
      document.querySelector(".commitment-search").submit();
    },
    includeSelectAllOption : false,
    numberDisplayed: 0,
		nonSelectedText: 'Select',
    buttonClass: 'custom-select',
    enableFiltering: false,
    includeResetOption: true,
    includeResetDivider: true,
    dropUp: false
  });
});


window.addEventListener('DOMContentLoaded', (event) => {
  var curSize = 16;
	$('.increasetext').click(function() {
		$('p').css('font-size', curSize += 1);
	});

	$('#resettext').click(function() {
			$('p').css('font-size', 16);
      curSize = 16;
	});

	$('.decreasetext').click(function() {
		$('p').css('font-size', curSize -= 1);
	});
});


